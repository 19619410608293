










































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import { inventoryType } from '../models/inventory.model';
import { Damage, damageReportValidation } from './models/damage.model';
import DriverInputWrapper from '@/shared/components/form/DriverInputWrapper.vue';
import { states } from '@/shared/models/geo.model';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import VueGallerySlideshow from 'vue-gallery-slideshow';
import { YmsService } from '@/shared/services/yms/yms.service';
import Loading from '@/shared/components/Loading.vue';
import { ToastHelper } from '@/utils/toast.util';
import { YmsType } from '../models/yms.model';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { FileUploadService } from '@/shared/services/fileUpload.service';
import { DivisionModule } from '@/store';

Component.registerHooks(['validations']);

@Component({
  components: {
    Fragment,
    DriverInputWrapper,
    VueGallerySlideshow,
    Loading
  }
})
export default class DamageReportAction extends Vue {
  @Prop({ default: null }) id: string;

  companyService = CompanyService;

  title = 'CREATE DAMAGE REPORT';

  divOptions = [];
  typeOptions = inventoryType;
  state = states;

  truckBodyCondition = false;

  loading = false;

  containerType = null;
  containerSize = null;
  chassisPool = null;
  shippingLine = null;
  selectValues = null;

  damageInst = new Damage();
  form: any = this.damageInst;
  fileUploadService = FileUploadService;

  index = null;

  photos = [];
  files = [];
  reportFiles = null;

  constructor() {
    super();
  }

  created() {
    this.getSelectValues();
  }

  mounted() {
    if (this.id) {
      this.getDamageData(this.id);
    }

    this.divOptions = DivisionModule.divisionsList.map(i => ({
      ...{
        value: i.abbr,
        text: i.name
      }
    }));
  }

  validations() {
    return {
      form: damageReportValidation
    };
  }

  validateState(name) {
    const { $dirty, $error } = this.$v.form[name];
    return $dirty ? !$error : null;
  }

  isFormInValid() {
    this.$v.form.$touch();
    return this.$v.form.$anyError;
  }

  close() {
    this.$bvModal.hide('damage-report-modal');
  }

  save() {
    if (this.isFormInValid()) {
      return;
    }

    if (!this.truckBodyCondition) {
      this.form.truckBodyCondition = '';
    }

    this.loading = true;
    this.id ? this.update() : this.create();
  }

  async create() {
    try {
      const data = JSON.parse(JSON.stringify(this.form));
      data.odometer = parseInt(data.odometer);

      await this.uploadFilesToDB();

      await YmsService.created(YmsType.DAMAGE, data);
      ToastHelper.show(`Damage report`, `Create successfully`, 5000, 'success');
      this.close();
      this.loading = false;
      this.$emit('reportUpdated');
    } catch (err) {
      this.loading = false;
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }

  async update() {
    try {
      const keys = [
        'id',
        'createdBy',
        'createdDate',
        'updatedBy',
        'updatedDate'
      ];

      const data = JSON.parse(JSON.stringify(this.form));

      keys.forEach(key => {
        data.files.forEach(file => {
          if (!file.tempS3FileName && !file.tempS3PreSignedUrl) {
            delete file.tempS3FileName;
            delete file.tempS3PreSignedUrl;
          }

          delete file[key];
        });
      });

      delete data.id;
      delete data.urls;
      data.odometer = parseInt(data.odometer);

      await this.uploadFilesToDB();

      await YmsService.update(YmsType.DAMAGE, this.id, data);
      ToastHelper.show(`Damage report`, `Update successfully`, 5000, 'success');
      this.close();
      this.loading = false;

      this.$emit('reportUpdated');
    } catch (err) {
      this.loading = false;
      ToastHelper.show(`Damage report`, err.message, 5000, 'danger');
    }
  }

  async removePhoto(index) {
    this.photos.splice(index, 1);

    if (this.reportFiles[index]) {
      await YmsService.removeDamageImage(
        this.form.id,
        this.reportFiles[index].id
      );
      this.reportFiles.splice(index, 1);
    }
  }

  async getSelectValues() {
    const {
      containerType,
      containerSize,
      chassisPool,
      shippingLine
    } = await WorkOrderService.getSelectValues();
    this.containerType = containerType;
    this.containerSize = containerSize;
    this.chassisPool = chassisPool;
    this.shippingLine = shippingLine;
  }

  async getDamageData(id) {
    this.loading = true;
    this.title = 'DAMAGE REPORT DETAILS';
    try {
      const invetoryResponse = await YmsService.getDamageData(id);
      this.form = { ...invetoryResponse.data };
      this.reportFiles = invetoryResponse.data.files;
      this.photos = [...invetoryResponse.data.urls];
      this.truckBodyCondition =
        invetoryResponse.data.truckBodyCondition?.length !== 0;

      this.loading = false;
    } catch (err) {
      this.loading = false;
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }

  async uploadFilesToDB() {
    const fileUploads = [];
    this.files.forEach(file => {
      if (file?.file) {
        fileUploads.push(
          this.fileUploadService.uploadFile(file.tempS3PreSignedUrl, file.file)
        );
      }
    });

    await Promise.all(fileUploads);
  }

  onPhotoUpload(file) {
    this.files.push({
      filename: file.filename,
      documentName: file.documentName,
      tempS3FileName: file.tempS3FileName,
      tempS3PreSignedUrl: file.tempS3PreSignedUrl,
      file: file.file
    });

    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onloadend = () => {
      this.photos.push(reader.result);
    };
    this.form.files.push({
      filename: file.filename,
      tempS3FileName: file.tempS3FileName,
      tempS3PreSignedUrl: file.tempS3PreSignedUrl,
      category: 'PHOTO',
      documentName: '',
      body: ''
    });
  }
}
